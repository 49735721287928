
/** 消息变量配置信息 */
interface MessageParamsConfig {
  /** 参数索引 */
  index: number;
  /** 消息模板 */
  mask: string;
  /** 额外参数列表 */
  paramList: string[];
}

/**
 * 获取函数的参数列表
 * @param {string} fn 目标函数
 * @returns {string[] | undefined} 函数的参数列表
 */
function getParameterName (fn?: any): string[] | undefined {
  if (typeof fn !== 'object' && typeof fn !== 'function') return
  const COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/mg
  const DEFAULT_PARAMS = /=[^,)]+/mg
  const FAT_ARROWS = /=>.*$/mg
  let code = fn.prototype ? fn.prototype.constructor.toString() : fn.toString()
  code = code
    .replace(COMMENTS, '')
    .replace(FAT_ARROWS, '')
    .replace(DEFAULT_PARAMS, '')
  const result = code.slice(code.indexOf('(') + 1, code.indexOf(')')).match(/([^\s,]+)/g)
  return result === null ? [] : result
}

/**
 * 确认弹窗装饰器
 * @param {String} title 弹窗标题
 * @param {string} message 弹窗消息，可使用'{param}'方式插入原函数的入参
 * @returns {Promise<any>} 返回原函数的返回值的promise封装
 */
export const Confirm = function (title = '操作确认', message = '是否执行该操作?'): MethodDecorator {
  return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const params = getParameterName(descriptor.value)
    const messageParamsConfig = message.match(/\{[0-9a-zA-Z_.]+\}/g)?.map(e => {
      const paramList = e.substring(1, e.length - 1).split('.')
      const index = params?.indexOf(paramList && paramList[0]) ?? -1
      if (~index) {
        paramList.splice(0, 1)
        return {
          index,
          mask: e,
          paramList
        }
      }
    }).filter((e): e is MessageParamsConfig => !!e)

    const origin = descriptor.value

    descriptor.value = function (...args: any[]) {
      return new Promise<any>((resolve, reject) => {
        messageParamsConfig?.forEach(e => {
          const arg = e.paramList.reduce((prev, item) => {
            return prev?.[item]
          }, args[e.index])
          message = message.replace(e.mask, arg)
        })
        ;(this as Vue).$Modal.confirm({
          title: title,
          content: message,
          onOk: () => {
            const result = origin.call(this, ...args)
            if (result instanceof Promise) {
              result.then(res => resolve(res)).catch(err => reject(err))
            } else {
              resolve(result)
            }
          }
        })
      })
    }
  }
}
