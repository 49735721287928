
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import Excel from 'exceljs'
import { BaseModal, showModal } from '@/libs/modal-helper'
import _ from 'lodash'
import { Table } from 'vxe-table'
import { CreateElement } from 'vue'
import { Confirm } from '@/decorato/confirm'

/**
 * 字段选项
 */
export interface FieldOption {
  /** 字段标题 */
  title: string;
  /** 字段名称 */
  name: string;
  /** 字段类型 */
  type?: string;
  /** 字段描述 */
  description?: string;
  /** 异步校验对象 */
  rules?: Record<string, any>[];
  /** 转换为预览数据的格式化方法 */
  viewFormatter?: (value: any) => any;
  /** 转换为导入数据的格式化方法 */
  importFormatter?: (value: any) => any;
}

export interface FieldConfig extends FieldOption {
  index?: number;
}

/** 导入失败行 */
export interface FailRow {
  rowKey: string;
  message: string;
}

/** 导入结果 */
export interface ImportResult {
  /** 请求失败信息 */
  requestFailMessage?: string;
  /** 行导入失败信息 */
  rowFail?: FailRow[];
}

export interface DataImportOption {
  /** 导入工作的唯一name，用于记录上次设置 */
  name: string;
  /** 导入弹窗标题 */
  title?: string;
  /** 目标数据表字段与标题 */
  targetFields?: FieldOption[];
  /** 导入前的数据映射方法 */
  beforeImportMapper?: (data: Record<string, any>) => Record<string, any>;
  /** 字段唯一性校验(填name) */
  uniqueRules?: string[][];
  /** 导入方法 */
  doImport?: (data: Record<string, any>[]) => Promise<ImportResult>;
  /** Excel预设 */
  preset?: PresetOption;
  /** 导入完成面板的自定义渲染函数 */
  renderFinish?: (h: CreateElement) => ReturnType<CreateElement>;
  /**
   * 前端数据行索引注入的字段名,用于后端反馈单行数据信息的索引。
   * @default rowKey
   */
  rowKeyField?: string;
  /**
   * 是否使用部分行错误模式
   *
   * 部分行错误模式可以在多次导入过程中忽略已成功行，重试导入错误行；不使用该模式则在发生错误后多次导入只重新导入全部数据。
   *
   * @default true
   *
   */
  rowFailMode?: boolean;
}

/** Excel预设选项 */
export interface PresetOption extends Required<ImportConfig> {
  /** 模板文件下载地址 */
  templateUrl?: string;
}

/**
 * 步骤选项
 */
interface StepOption {
  /** 步骤标题 */
  label: string;
  /** 下一步拒绝信息 */
  injectMessage?: string;
}

/**
 * 导入配置
 */
interface ImportConfig {
  /** 目标工作表 */
  sheet?: string;
  /** 标题行 */
  titleRow?: number;
  /** 目标数据表字段与标题 */
  targetFields?: FieldConfig[];
}

/**
 * 标题行选项
 */
interface ColOption {
  index: number;
  name: string;
}

/**
 * 单元格着色选项
 *
 * 只填写`rowIndex`或`colIndex`时，表示着色整行或整列
 *
 * 同时填写`rowIndex`和`colIndex`时，表示着色单元格
 *
 * 全不填写时，忽略
 */
interface CellStyleOption {
  /** 行索引 */
  rowIndex?: number;
  /** 列索引 */
  colIndex?: number;
  /** 着色类型 */
  type: 'error' | 'success';
}

/**
 * 数据导入组件
 */
@Component({
  name: 'DataImport'
})
export default class DataImport extends Vue implements BaseModal {
  /** 导入工作的唯一name，用于记录上次设置 */
  @Prop({
    type: String,
    required: true
  })
  readonly name!: string

  /** 数据导入标题 */
  @Prop({
    type: String,
    default: '数据导入',
    required: false
  })
  readonly title!: string

  /** 目标字段列表 */
  @Prop({
    type: Array,
    default: () => [],
    required: false
  })
  readonly targetFields!: FieldOption[]

  /** 字段唯一性校验 */
  @Prop({
    type: Array,
    default: () => [],
    required: false
  })
  readonly uniqueRules!: string[][]

  /** 导入前的数据映射方法 */
  @Prop({
    type: Function,
    required: false
  })
  readonly beforeImportMapper?: (
    data: Record<string, any>
  ) => Record<string, any>

  /** 导入方法 */
  @Prop({
    type: Function,
    required: false
  })
  readonly doImport?: (data: Record<string, any>[]) => Promise<ImportResult>

  /** 导入预设 */
  @Prop({
    type: Object,
    required: false
  })
  readonly preset?: PresetOption

  /** 完成导入面板的自定义渲染函数 */
  @Prop({
    type: Function,
    required: false
  })
  readonly renderFinish?: (h: CreateElement) => ReturnType<CreateElement>

  /** 行key字段 */
  @Prop({
    type: String,
    required: false,
    default: 'rowKey'
  })
  readonly rowKeyField!: string

  /** 是否使用部分行错误模式 */
  @Prop({
    type: Boolean,
    default: true,
    required: false
  })
  readonly rowFailMode!: boolean

  @Ref()
  readonly dataTable!: Table

  loading = false
  isShown = false

  /** 导入步骤 */
  get steps (): StepOption[] {
    const step = [
      {
        label: '选择Excel文件',
        injectMessage: '请选择一个xlsx文件以导入'
      },
      {
        label: '填写导入配置',
        injectMessage: '请为每个字段设置对应的列'
      },
      {
        label: '数据预览',
        injectMessage: '请通过数据校验'
      },
      {
        label: '执行导入',
        injectMessage: '请点击按钮导入数据'
      },
      {
        label: '操作结果'
      }
    ]

    // 有预设删除第二步骤
    if (this.preset) {
      step.splice(1, 1)
    }

    return step
  }

  /** 根据是否有预设修正用于组件显示的步骤索引 */
  get withPresetStep () {
    if (!this.preset || this.curStep < 1) {
      return this.curStep
    } else {
      return this.curStep - 1
    }
  }

  get redoImportButtonLabel () {
    if (this.importErrorMessage) {
      if (this.rowFailMode && this.hasFailCols) {
        return '重新导入失败数据'
      } else {
        return '重新导入'
      }
    } else {
      return '开始导入'
    }
  }

  /** 当前步骤 */
  curStep = 0
  /** 可下一步 */
  async canBeNext (): Promise<boolean> {
    switch (this.curStep) {
      case 0: // 选择Excel文件
        return !_.isNil(this.workbook)
      case 1: // 填写导入配置
        return (
          (this.importConfig?.targetFields?.filter(e => _.isNil(e.index))
            ?.length || 0) === 0
        )
      case 2: // 数据预览
        await this.clearCellStyle()
        // eslint-disable-next-line no-case-declarations
        const err = await this?.dataTable?.validate(true).catch(e => e)
        if (err) {
          throw new Error(
            Object.values(err)
              ?.flat()
              ?.filter((e: any) => !_.isNil(e.rowIndex))
              ?.map((e: any) => `第${e.rowIndex + 1}行数据校验失败`)
              ?.join('\n') || '表格数据校验失败'
          )
        }
        this.uniqueCheck(this.willImportDatas)
        await this.applyCellStyle()
        return true
      case 3: // 执行导入
        return this.rowFailMode
      default:
        return false
    }
  }

  get canBeBack (): boolean {
    if (this.curStep <= 0 || this.curStep === 4) {
      return false
    }

    if (this.curStep === 2 && this.importErrorMessage) {
      return false
    }

    return true
  }

  /** 处理下一步按钮点击 */
  async handlerNext () {
    try {
      this.loading = true
      if (!(await this.canBeNext())) {
        throw new Error(
          this.steps?.[this.curStep]?.injectMessage || '请先完成当前步骤内容'
        )
      }
      // 切换时执行内容
      switch (this.curStep) {
        case 0: // 选择Excel文件
          // 如果有预设，应用并直接跳过第二步
          if (this.preset) {
            this.importConfig = {
              ...this.preset,
              templateUrl: undefined
            } as PresetOption
            this.createWillImportDatas()
            this.curStep++
          }
          break
        case 1: // 填写导入配置
          this.createWillImportDatas()
          break
        case 2: // 数据预览
          break
        default:
          break
      }
      this.curStep++
    } catch (e) {
      console.error(e)
      this.$Message.error({
        content: (e as any)?.message || e
      })
    } finally {
      this.loading = false
    }
  }

  async handleStepBack () {
    try {
      this.loading = true
      if (this.preset && this.curStep === 2) {
        this.curStep -= 2
      } else {
        this.curStep--
      }
    } catch (e) {
      console.error(e)
      this.$Message.error({
        content: (e as any)?.message || e
      })
    } finally {
      this.loading = false
    }
  }

  @Confirm('跳过导入失败数据', '确定要跳过导入失败的数据吗？')
  handleSkipFailRow () {
    this.handlerNext()
  }

  get finishPanel (): any {
    if (this.renderFinish) {
      return {
        name: 'FinishPanel',
        render: this.renderFinish
      }
    }
  }

  show (): void {
    this.isShown = true
  }

  close (): void {
    this.isShown = false
  }

  doSave (): void {
    this.$emit('onOk')
  }

  doCancel (): void {
    this.$emit('onCancel')
  }

  created () {
    this.importConfig.targetFields = _.cloneDeep(this.targetFields)
    this.importConfig.targetFields.forEach(e => {
      if (e.rules) {
        this.validateRules[e.name] = e.rules
      }
    })
  }

  /**
   * 创建弹窗
   */
  public static create (option: DataImportOption): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      showModal<void>(
        this,
        {
          props: {
            ...option
          }
        },
        true,
        async () => {
          resolve()
        },
        reject
      )
    })
  }

  /**
   * Excel工作簿
   */
  workbook: Excel.Workbook | null = null
  /**
   * 选择的文件对象
   */
  file: File | null = null

  /**
   * 选择并实例化Excel对象
   */
  createExcel () {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.xlsx'
    input.oninput = async () => {
      try {
        const file = input.files?.[0]
        if (!file) {
          throw new Error('请选择文件')
        }
        this.file = file
        this.workbook = await new Excel.Workbook().xlsx.load(
          await file.arrayBuffer()
        )
      } catch (e) {
        console.error(e)
        this.$Notice.error({
          title: '导入失败',
          desc: (e as any)?.message || e
        })
        this.workbook = null
        this.file = null
      } finally {
        input.remove()
      }
    }
    input.click()
  }

  /**
   * 导入配置
   */
  importConfig: ImportConfig = {
    titleRow: 1
  }

  /**
   * 目标工作表
   */
  get targetSheet () {
    return this.workbook?.getWorksheet(this.importConfig.sheet || '')
  }

  get workSheets () {
    return this.workbook?.worksheets || []
  }

  get titleRow (): ColOption[] {
    if (_.isNil(this.importConfig.titleRow)) {
      return []
    }
    const values =
      (this.targetSheet?.getRow(this.importConfig.titleRow)?.values as any[]) ||
      []

    return (
      (values
        .map((e: any, i: number) => {
          if (typeof e === 'string' || typeof e === 'number') {
            return {
              index: i,
              name: e
            }
          }
        })
        .filter((e: any) => !!e) as ColOption[]) || []
    )
  }

  /**
   * 准备要导入的数据
   */
  willImportDatas: Record<string, any>[] = []
  /**
   * 校验规则
   */
  validateRules: Record<string, Record<string, any>[]> = {}

  /** 从excel表提取准备要导入的数据 */
  createWillImportDatas () {
    try {
      this.willImportDatas = []

      this.targetSheet?.eachRow((row, index) => {
        if (index <= this.importConfig.titleRow!) {
          return
        }
        const item: Record<string, any> = {}
        this.importConfig.targetFields?.forEach(e => {
          const _formatter = e.viewFormatter || (e => e)
          item[e.name] = _formatter(row.getCell(e.index || -1)?.value)
        })
        item[this.rowKeyField] = `rk_${index}`
        this.willImportDatas.push(item)
      })
    } catch (e) {
      console.error(e)
      this.$Notice.error({
        title: '创建导入数据失败',
        desc: (e as any)?.message || e
      })
    }
  }

  /**
   * 唯一校验
   */
  uniqueCheck (datas: Record<string, any>[]) {
    this.uniqueRules.forEach(e => {
      const _datas = datas
        .map((item, index) => {
          // 确保参与唯一判断的字段都有值
          for (const v of e) {
            if (_.isNil(item[v])) {
              return
            }
          }

          return [e.map(c => String(item[c])).join('_'), index] // 拼接唯一判断的字段值
        })
        .filter(item => !_.isNil(item)) as [string, number][]

      _datas.forEach(item => {
        const _data = _datas.find(d => d[0] === item[0])
        if (!_data) return
        if (_data[1] !== item[1]) {
          this.setCellStyle(
            [
              {
                type: 'error',
                rowIndex: _data[1]
              },
              {
                type: 'error',
                rowIndex: item[1]
              }
            ],
            false
          )
          throw new Error(
            `[${e.map(this.findFieldTitle).join(' + ')}]不能重复(${
              _data[1] + 1
            }行与${item[1] + 1}行)`
          )
        }
      })
    })
  }

  /**
   * 通过字段名获取字段标题
   */
  findFieldTitle (fieldName: string): string {
    return (
      this.importConfig.targetFields?.find(e => e.name === fieldName)?.title ||
      fieldName
    )
  }

  readonly dataFilter = {
    /** 隐藏成功的数据 */
    hideSuccess: true
  }

  /** 提供给表格显示的数据，经过过滤 */
  get shownWillImportDatas () {
    // 导入错误时使用过滤器
    if (this.importErrorMessage) {
      return this.willImportDatas.filter(e => {
        if (this.dataFilter.hideSuccess && !this.isFailCol(e)) {
          return false
        }

        return true
      })
    }

    return this.willImportDatas
  }

  /** 导入加载状态 */
  importLoading = false
  /** 导入错误信息 */
  importErrorMessage = ''
  /** 导入失败列 */
  importFailCols: FailRow[] = []

  get hasFailCols () {
    return this.importFailCols.length > 0
  }

  /** 判断某行数据是否为错误行 */
  isFailCol (row: any) {
    return this.importFailCols.some(e => e.rowKey === row[this.rowKeyField])
  }

  /** 判断某行数据是否为错误行，获取错误信息 */
  getFailMessage (row: any) {
    return (
      this.importFailCols.find(e => e.rowKey === row[this.rowKeyField])
        ?.message || ''
    )
  }

  isEditableCol (row: any) {
    // 没有发生导入错误或者非错误行模式都可以编辑
    if (!this.importErrorMessage || !this.rowFailMode) {
      return true
    }

    // 错误行模式下，只有错误行可以编辑
    return this.isFailCol(row)
  }

  /** 格式化数据用于导入 */
  formatDataToImport (data: Record<string, any>[]) {
    let _data = _.cloneDeep(data)

    this.targetFields.forEach(field => {
      if (typeof field.importFormatter === 'function') {
        for (const e of _data) {
          if (!_.isNil(e[field.name])) {
            e[field.name] = field.importFormatter(e[field.name])
          }
        }
      }
    })

    if (typeof this.beforeImportMapper === 'function') {
      _data = _data.map(d => this.beforeImportMapper!(d))
    }

    return _data
  }

  async handlerImport () {
    this.importLoading = true
    if (!this.preset) {
      this.saveConfigToLocal()
    }
    try {
      if (typeof this.doImport !== 'function') {
        throw new Error('数据导入方法未定义')
      }

      let _willImportDatas: Record<string, any>[] = this.willImportDatas
      // 发生过错误、处于行错误模式，只导入错误行
      if (this.importErrorMessage && this.rowFailMode) {
        _willImportDatas = this.willImportDatas.filter(item => {
          return this.importFailCols.some(
            e => e.rowKey === item[this.rowKeyField]
          )
        })
      }

      const importResult = await this.doImport(this.formatDataToImport(_willImportDatas))

      // 请求失败
      if (importResult.requestFailMessage) {
        throw new Error(importResult.requestFailMessage)
      } else if (!_.isEmpty(importResult.rowFail)) {
        // 部分行失败
        if (this.rowFailMode) {
          this.importFailCols = importResult.rowFail!
        }
        const failCount = importResult.rowFail!.length
        throw new Error(
          `累计${
            this.willImportDatas.length - failCount
          } 行数据导入成功，${failCount} 行数据导入失败`
        )
      }

      this.importErrorMessage = ''
      this.importFailCols = []
      this.curStep++
    } catch (e) {
      // 如果行错误模式，且目前没有错误行，全部行加入错误
      if (this.rowFailMode && _.isEmpty(this.importFailCols)) {
        this.importFailCols = this.willImportDatas.map(data => {
          return {
            rowKey: data[this.rowKeyField],
            message: (e as any)?.message || e
          }
        })
      }
      console.error(e)
      this.$Notice.error({
        title: '导入失败',
        desc: (e as any)?.message || e
      })
      this.importErrorMessage = (e as any)?.message || String(e)
    } finally {
      this.importLoading = false
    }
  }

  /** 查找本地上次配置 */
  get hasLocalConfig () {
    return !!localStorage.getItem('importConfig_' + this.name)
  }

  /** 应用本地上次配置 */
  appendLocalConfig () {
    const localConfig = localStorage.getItem('importConfig_' + this.name)
    if (localConfig) {
      const config: ImportConfig = JSON.parse(localConfig)
      this.$set(this.importConfig, 'sheet', config.sheet)
      this.$set(this.importConfig, 'titleRow', config.titleRow)
      this.importConfig.targetFields?.forEach((e, i) => {
        this.$set(e, 'index', config.targetFields?.[i]?.index ?? e.index)
      })
    }
  }

  /** 保存配置到本地 */
  saveConfigToLocal () {
    localStorage.setItem(
      'importConfig_' + this.name,
      JSON.stringify(this.importConfig)
    )
  }

  @Confirm('确定要删除该行数据吗？', '删除后将不可恢复')
  handleDeleteRow (row: any) {
    const index = this.willImportDatas.findIndex(e => e[this.rowKeyField] === row[this.rowKeyField])
    if (index > -1) {
      this.willImportDatas.splice(index, 1)
    }
  }

  /** 下载模板文件 */
  handleDownloadTemplate () {
    if (this.preset?.templateUrl) {
      const a = document.createElement('a')
      a.href = this.preset?.templateUrl
      a.target = '_blank'
      a.click()
      a.remove()
    }
  }

  /** 单元格样式 */
  cellStyles: CellStyleOption[] = []

  /** 处理动态单元格样式 */
  handleCellStyle ({
    rowIndex,
    columnIndex,
    column,
    row
  }: {
    rowIndex: number;
    columnIndex: number;
    column: any;
    row: any;
  }) {
    if (column.property === 'action') {
      return
    }

    let cellStyle: CellStyleOption['type'] | undefined

    // 着色失败行
    if (this.rowFailMode && this.isFailCol(row)) {
      cellStyle = 'error'
    }

    for (const item of this.cellStyles) {
      if (_.isNil(item.rowIndex) && _.isNil(item.colIndex)) {
        continue
      }

      if (_.isNil(item.rowIndex) && item.colIndex === columnIndex) {
        cellStyle = item.type
        break
      } else if (_.isNil(item.colIndex) && item.rowIndex === rowIndex) {
        cellStyle = item.type
        break
      } else if (item.rowIndex === rowIndex && item.colIndex === columnIndex) {
        cellStyle = item.type
        break
      }
    }

    if (cellStyle) {
      return {
        backgroundColor: cellStyle === 'success' ? '#C8E6C9' : '#FFCDD2'
      }
    }
  }

  /** 清空着色单元格 */
  async clearCellStyle () {
    this.cellStyles = []
    this.dataTable?.updateData()
  }

  /** 设置着色单元格 */
  async setCellStyle (
    option: CellStyleOption[] | CellStyleOption,
    refresh = true
  ) {
    const _option = Array.isArray(option) ? option : [option]
    this.cellStyles.push(..._option)

    if (refresh) {
      this.dataTable?.updateData()
    }
  }

  async applyCellStyle () {
    if (this.cellStyles.length > 0) {
      await this.dataTable?.updateData()
    }
  }
}
