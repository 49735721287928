/**
 * 用户模块
 */

import DefaultApiService, { BaseHasTimeEntity } from '@/libs/api-request'
import { DataApiResult } from '@/libs/http-request'

/**
 * 用户接口
 */
export interface User extends BaseHasTimeEntity{
  // 用户id
  userCode?: string;
  // 昵称
  nickName?: string;
  // 登录账号
  userLoginName?: string;
  // 用户手机
  phone?: string;
  // 密码
  pwd?: string;

  // 仅在批量导入使用的明文密码
  textPwd?: string;

  // 是否旧用户
  oldRegister?: string;
}

/**
 * 用户的团队信息
 */
export interface UserTeamInfo{
  // 用户团队id
  teamId: number;
  // 用户团队名
  teamName: string;
  // 用户团队code
  teamCode: string;
}

export interface FailRow {
  rowKey: string;
  message: string;
}

export interface ImportUsersVO extends User {
  /** 前端导入数据行唯一key */
  rowKey: string;
  /** 是否有权限添加用户 */
  canAddUser?: boolean;
  /** 是否有限创建工程 */
  canCreateProject?: boolean;
  /** 是否有限删除工程 */
  canDeleteProject?: boolean;
  /** 是否锁定项目组 */
  lockProjectGroup?: boolean;
  /** 是否有创建项目组 */
  createProjectGroup?: boolean;
  /** 是否有删除项目组 */
  deleteProjectGroup?: boolean;
  /** 是否有权操作服务器 */
  canOperatePcServer?: boolean;
  /** 是否有权操作应用中心 */
  canOperateApplication?: boolean;
  /** 是否有权操作cloud基础资源 */
  canOperateCloudBaseResource?: boolean;
  /** 是否有权操作镜像资源 */
  canOperateImageResource?: boolean;
  /** 是否有权操作配置中心 */
  canOperateRegisterCenter?: boolean;
  /** 是否有权操作api网关 */
  canOperateApiGateway?: boolean;
  /* 是否有权操作资源 */
  canOperateResource?: boolean;
}

export interface ImportUsersResutlVO {
  requestFailMessage?: string;
  rowFail?: FailRow[];
  rowSuccess?: User[];
}

/**
 * 用户服务请求api接口
 */
class UserApi extends DefaultApiService<User> {
  constructor () {
    super('/api/user')
  }

  // 根据手机号和登录密码找到对象
  getItemByPhoneAndPwd (user: User) {
    return this.request('getItemByPhoneAndPwd', user)
  }

  // 根据手机号找到对象
  getItemByPhone (user: User) {
    return this.request('getItemByPhone', user)
  }

  // 根据登录账号找到对象
  getItemByUserLoginName (userLoginName: string) {
    return this.request(
      'getItemByUserLoginName/' + userLoginName,
      null,
      'POST'
    )
  }

  /** 批量导入用户 */
  importUsers (data: ImportUsersVO[]) {
    return this.request('importUsers', data, 'POST') as Promise<DataApiResult<ImportUsersResutlVO>>
  }
}

export default new UserApi()
