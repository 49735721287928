
import { Component, Vue } from 'vue-property-decorator'
import DataImport, {
  PresetOption,
  FieldConfig
} from '@/components/data-import.vue'
import userApi, { User } from '@/api/user'
import { Button } from 'view-design'
import Excel from 'exceljs'
import FileSaver from 'file-saver'

/** 用户批量导入 */
@Component({
  name: 'UserImport'
})
export default class UserImport extends Vue {
  /** 加载状态 */
  loading = false

  /** 导入目标字段 */
  readonly targetFields: FieldConfig[] = [
    {
      title: '登录名',
      name: 'userLoginName',
      type: 'string',
      description: '账户登录名',
      index: 1,
      rules: [
        {
          required: true,
          message: '登录名不能为空'
        }, {
          message: '登录名长度不能超过8字符',
          max: 8
        }
      ]
    },
    {
      title: '昵称',
      name: 'nickName',
      type: 'string',
      description: '账户昵称',
      index: 2,
      rules: [
        {
          required: true,
          message: '昵称不能为空'
        }, {
          message: '昵称长度不能超过8字符',
          max: 8
        }
      ]
    },
    {
      title: '电话号码',
      name: 'phone',
      type: 'string',
      description: '账户电话号码',
      index: 3,
      rules: [
        {
          required: true,
          message: '电话号码不能为空'
        },
        {
          message: '电话号码长度应该是11-16位',
          min: 11,
          max: 16
        }
      ],
      viewFormatter: value => String(value)
    },
    {
      title: '是否有权限添加用户',
      name: 'canAddUser',
      type: 'string',
      description: '账号是否有权限添加用户',
      index: 4,
      rules: [
        {
          type: 'string',
          message: '请填写是或否',
          pattern: /^(是|否)$/
        }
      ],
      importFormatter: value => value === '是'
    },
    {
      title: '是否有权限创建工程',
      name: 'canCreateProject',
      type: 'string',
      description: '账号是否有权限添加用户',
      index: 5,
      rules: [
        {
          type: 'string',
          message: '请填写是、否或留空',
          pattern: /^(是|否)$/
        }
      ],
      importFormatter: value => value === '是'
    },
    {
      title: '是否有权限删除工程',
      name: 'canDeleteProject',
      type: 'string',
      description: '账号是否有权限添加用户',
      index: 6,
      rules: [
        {
          type: 'string',
          message: '请填写是、否或留空',
          pattern: /^(是|否)$/
        }
      ],
      importFormatter: value => value === '是'
    },
    {
      title: '是否有权限锁定项目组',
      name: 'lockProjectGroup',
      type: 'string',
      description: '账号是否有权限添加用户',
      index: 7,
      rules: [
        {
          type: 'string',
          message: '请填写是、否或留空',
          pattern: /^(是|否)$/
        }
      ],
      importFormatter: value => value === '是'
    },
    {
      title: '是否有权限创建项目组',
      name: 'createProjectGroup',
      type: 'string',
      description: '账号是否有权限添加用户',
      index: 8,
      rules: [
        {
          type: 'string',
          message: '请填写是、否或留空',
          pattern: /^(是|否)$/
        }
      ],
      importFormatter: value => value === '是'
    },
    {
      title: '是否有权限删除项目组',
      name: 'deleteProjectGroup',
      type: 'string',
      description: '账号是否有权限添加用户',
      index: 9,
      rules: [
        {
          type: 'string',
          message: '请填写是、否或留空',
          pattern: /^(是|否)$/
        }
      ],
      importFormatter: value => value === '是'
    },
    {
      title: '是否有权操作服务器',
      name: 'canOperatePcServer',
      type: 'string',
      description: '账号是否有权限添加用户',
      index: 10,
      rules: [
        {
          type: 'string',
          message: '请填写是、否或留空',
          pattern: /^(是|否)$/
        }
      ],
      importFormatter: value => value === '是'
    },
    {
      title: '是否有权操作应用中心',
      name: 'canOperateApplication',
      type: 'string',
      description: '账号是否有权限添加用户',
      index: 11,
      rules: [
        {
          type: 'string',
          message: '请填写是、否或留空',
          pattern: /^(是|否)$/
        }
      ],
      importFormatter: value => value === '是'
    },
    {
      title: '是否有权操作cloud基础资源',
      name: 'canOperateCloudBaseResource',
      type: 'string',
      description: '账号是否有权限添加用户',
      index: 12,
      rules: [
        {
          type: 'string',
          message: '请填写是、否或留空',
          pattern: /^(是|否)$/
        }
      ],
      importFormatter: value => value === '是'
    },
    {
      title: '是否有权操作镜像资源',
      name: 'canOperateImageResource',
      type: 'string',
      description: '账号是否有权限添加用户',
      index: 13,
      rules: [
        {
          type: 'string',
          message: '请填写是、否或留空',
          pattern: /^(是|否)$/
        }
      ],
      importFormatter: value => value === '是'
    },
    {
      title: '是否有权操作配置中心',
      name: 'canOperateRegisterCenter',
      type: 'string',
      description: '账号是否有权限添加用户',
      index: 14,
      rules: [
        {
          type: 'string',
          message: '请填写是、否或留空',
          pattern: /^(是|否)$/
        }
      ],
      importFormatter: value => value === '是'
    },
    {
      title: '是否有权操作api网关',
      name: 'canOperateApiGateway',
      type: 'string',
      description: '账号是否有权限添加用户',
      index: 15,
      rules: [
        {
          type: 'string',
          message: '请填写是、否或留空',
          pattern: /^(是|否)$/
        }
      ],
      importFormatter: value => value === '是'
    },
    {
      title: '是否有权操作资源',
      name: 'canOperateResource',
      type: 'string',
      description: '账号是否有权操作资源',
      index: 16,
      rules: [
        {
          type: 'string',
          message: '请填写是、否或留空',
          pattern: /^(是|否)$/
        }
      ],
      importFormatter: value => value === '是'
    }
  ]

  /** 导入预设 */
  preset: PresetOption = {
    templateUrl: '/deploy/import-template/userImport.xlsx',
    sheet: '批量导入',
    titleRow: 1,
    targetFields: this.targetFields
  }

  /** 已经成功导入数据的列表，用于稍后导出回执 */
  successList: User[] = []
  downlonding = false

  async handleClick () {
    try {
      this.loading = true
      await DataImport.create({
        name: 'userImport',
        title: '批量导入用户',
        preset: this.preset,
        targetFields: this.targetFields,
        uniqueRules: [['userLoginName'], ['nickName'], ['phone']],
        doImport: async (data) => {
          const result = await userApi.importUsers(data as any)
          if (result.data?.rowSuccess) {
            this.successList.push(...result.data?.rowSuccess)
          }
          return result.data!
        },
        beforeImportMapper: (data) => ({ ...data, nickName: data.userLoginName, userCode: '0000' }),
        renderFinish: h => {
          return h(
            'div',
            {
              attrs: {
                class: 'd-flex flex-column justify-center align-center'
              }
            },
            [
              h('h3', '点击下载回执文件，弹窗关闭后无法再次下载'),
              h('div', [
                h(
                  Button,
                  {
                    props: {
                      type: 'primary',
                      loading: this.downlonding
                    },
                    attrs: {
                      style: 'margin-top: 12px;'
                    },
                    on: {
                      click: async () => {
                        try {
                          this.downlonding = true

                          const workbook = new Excel.Workbook()
                          const worksheet = workbook.addWorksheet('用户导入回执')
                          worksheet.addRow(['用户登录名', '昵称', '手机号', '初始密码'])
                          worksheet.addRows(this.successList.map(e => [e.userLoginName, e.nickName, e.phone, e.textPwd]))

                          const buff = await workbook.xlsx.writeBuffer({ filename: 'userImportReport' })
                          FileSaver.saveAs(new Blob([buff]), '用户导入回执.xlsx')
                        } catch (e) {
                          console.error(e)
                          this.$Message.error({
                            content: `回执文件下载失败:${(e as any)?.message || e}`
                          })
                        } finally {
                          this.downlonding = false
                        }
                      }
                    }
                  },
                  '下载回执文件'
                )
              ])
            ]
          )
        }
      })
    } finally {
      this.loading = false
      this.successList = []
    }
  }
}
