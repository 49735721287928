
import BaseTablePanel from '@/frame/share/table-panel'
import { Component } from 'vue-property-decorator'
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import TablePanel from '@/frame/share/table-panel.vue'
import tableConfig from './index-table-config'
import { PagerQueryBean, PageListData, PageDataApiResult } from '@/libs/http-request'
import _ from 'lodash'
import teamUserApi, { TeamUse } from '@/api/team-user'
import { PageInfo } from '@/libs/api-request'
import showModiAtTeamNameModal from './modi-atteam-name-modal'
import { dispatchUpdateUserInfo, getterUserInfo } from '@/store'
import UserImport from './user-import.vue'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: ['-modifyTime'],
  nameAtTeam: undefined,
  userPhone: undefined,
  userCode: undefined
}

@Component({
  name: 'teamMembersDataList',
  components: { ExpendSearchPanel, TablePanel, UserImport }
})
export default class TeamMembersDataList extends BaseTablePanel<TeamUse> {
    loading=false
  /// 表格定义
  tableConfig=tableConfig

  /// 查询数据
  queryData: PagerQueryBean=_.cloneDeep(defaultQuery)

  pageData: PageListData<TeamUse>={
    items: [],
    total: 0,
    pageSize: 10
  }

  /**
   * 工具栏按钮事件
   */
  protected doToolBarButtonAction (code: string) {
    this.toolBarButtonAction(code, (this.$refs.tablePanel as any).getTableObject())
  }

  /**
   * 从服务器拉取数据
   */
  protected onPullDataListFromServer (pagerQueryBean: PagerQueryBean): Promise<PageDataApiResult<TeamUse>> {
    return teamUserApi.teamUserList(pagerQueryBean)
  }

  /**
   * 收缩搜索条
   */
  shrinkSeachbar () {
    const newQuery = _.cloneDeep(defaultQuery)
    newQuery.nameAtTeam = this.queryData.nameAtTeam
    if (!_.isEqual(newQuery, this.queryData)) {
      this.$nextTick(() => {
        this.queryData = newQuery
        this.doPullDataList()
      })
    }
  }

  /**
   * 页面数据拉取
   */
  protected doPullDataList (pageInfo?: PageInfo) {
    pageInfo = pageInfo || { curPage: 1, pageSize: 10 }

    const that = this

    this.pullDataList(pageInfo).then(response => {
      that.pageData = response;
      (that.$refs.tablePanel as any).setCurPage(pageInfo?.curPage)
    })
  }

  /**
   * 加载数据
   */
  mounted () {
    this.$nextTick(() => {
      this.doPullDataList()
    })
  }

  /**
   * 是否禁用
   */
  disableChange (row: TeamUse) {
    teamUserApi.disableChange(row.id!, row.disable!)
      .then(res => {
        this.$Notice.success(
          {
            title: '提示',
            desc: '修改成功'
          }
        )
      })
      .catch((err: Error) => {
        this.$Notice.error({
          title: '提示',
          desc: err.message
        })
      })
      .finally(() => this.finishLoading())
  }

  /**
   * 批复用户申请
   */
  canAddUserChange (row: TeamUse) {
    this.updateTeamMemberPermission(row.id!, row.canAddUser!, teamUserApi.canAddUser)
  }

  /**
   * 创建工程
   */
  canCreateProjectChange (row: TeamUse) {
    this.updateTeamMemberPermission(row.id!, row.canCreateProject!, teamUserApi.canCreateProject)
  }

  /**
   * 删除工程
   */
  canDeleteProjectChange (row: TeamUse) {
    this.updateTeamMemberPermission(row.id!, row.canDeleteProject!, teamUserApi.canDeleteProject)
  }

  /**
   * 锁定项目
   */
  lockProjectGroupChange (row: TeamUse) {
    this.updateTeamMemberPermission(row.id!, row.lockProjectGroup!, teamUserApi.lockProjectGroup)
  }

  /**
   * 创建项目
   */
  createProjectGroupChange (row: TeamUse) {
    this.updateTeamMemberPermission(row.id!, row.createProjectGroup!, teamUserApi.createProjectGroup)
  }

  /**
   * 删除项目
   */
  deleteProjectGroupChange (row: TeamUse) {
    this.updateTeamMemberPermission(row.id!, row.deleteProjectGroup!, teamUserApi.deleteProjectGroup)
  }

  /** 编辑服务器 */
  canOperatePcServer (row: TeamUse) {
    this.updateTeamMemberPermission(row.id!, row.canOperatePcServer!, teamUserApi.canOperatePcServer)
  }

  /** 编辑应用中心 */
  canOperateApplication (row: TeamUse) {
    this.updateTeamMemberPermission(row.id!, row.canOperateApplication!, teamUserApi.canOperateApplication)
  }

  /** 编辑企业微服务基础资源 */
  canOperateCloudBaseResource (row: TeamUse) {
    this.updateTeamMemberPermission(row.id!, row.canOperateCloudBaseResource!, teamUserApi.canOperateCloudBaseResource)
  }

  /** 编辑镜像资源 */
  canOperateImageResource (row: TeamUse) {
    this.updateTeamMemberPermission(row.id!, row.canOperateImageResource!, teamUserApi.canOperateImageResource)
  }

  /** 编辑配置中心 */
  canOperateRegisterCenter (row: TeamUse) {
    this.updateTeamMemberPermission(row.id!, row.canOperateRegisterCenter!, teamUserApi.canOperateRegisterCenter)
  }

  /** 编辑API网关 */
  canOperateApiGateway (row: TeamUse) {
    this.updateTeamMemberPermission(row.id!, row.canOperateApiGateway!, teamUserApi.canOperateApiGateway)
  }

  /** 管理资源 */
  canOperateResource (row: TeamUse) {
    this.updateTeamMemberPermission(row.id!, row.canOperateResource!, teamUserApi.canOperateResource)
  }

  /**
   * 修改团队小组成员权限
   */
  updateTeamMemberPermission (id: number, disable: boolean, flag: string) {
    teamUserApi.updateTeamMemberPermission(id, disable, flag)
      .then(res => {
        dispatchUpdateUserInfo(true)
        this.$Notice.success(
          {
            title: '提示',
            desc: '修改成功'
          }
        )
      })
      .catch((err: Error) => {
        this.$Notice.error({
          title: '错误',
          desc: err.message
        })
      })
      .finally(() => this.finishLoading())
  }

  modiUserName (row: TeamUse) {
    showModiAtTeamNameModal(row.userId!, row.nameAtTeam!)
      .then((name) => {
        row.nameAtTeam = name
      })
  }

  /**
   * 表格中的行是否为当前行
   */
  get curUserId () {
    const userInfo = getterUserInfo()
    return userInfo.userId
  }

  /**
   * 用户管理权限
   */
  get canEdit () {
    const userInfo = getterUserInfo()
    return userInfo.canAddUser
  }
}

