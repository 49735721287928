import { ToolBarCodeExportAll } from '@/frame/share/table-panel'

/// 表格基本配置
const options = {
  align: 'center'
}
/// 导出列定义
const columns = [
  { type: 'checkbox', width: 40 },
  { type: 'seq', title: '序号', width: 60 },
  { field: 'userCode', title: '用户ID', width: 130 },
  { field: 'userLoginName', title: '用户登录名', width: 130 },
  { field: 'teamName', title: '所属团队', width: 160 },
  { slot: 'nameAtTeam' },
  { field: 'userPhone', title: '用户手机号', width: 160 },
  { field: 'addTime', title: '加入时间', sortable: true, width: 180 },
  { field: 'disable', title: '状态(是否禁用)', slot: 'disableSlot' },
  { slot: 'membersPermissionSlot' },
  { width: 40, slot: 'col_action' }
]

/// 导出工具栏定义
const toolbarConfig = {
  buttons: [
    { code: 'insert', name: '新增', icon: 'md-add' },
    { code: 'delete', name: '直接删除', icon: 'fa fa-trash-o', status: 'error' },
    { code: ToolBarCodeExportAll, name: '全量导出', icon: 'vxe-icon--download', status: 'success' }
  ],
  refresh: true,
  export: true,
  print: true,
  zoom: true,
  custom: true,
  exportall: true
}
/**
 * 表格的打印配置
 */
const printConfig = {
  sheetName: '打印表格'
}

/**
 * 表格的导出配置
 */
const tableExport = {
// 默认选中类型
  type: 'xlsx',
  // 自定义类型
  types: ['xlsx', 'csv', 'html', 'xml', 'txt']
}

/// 导出配置
export default {
  options: options,
  columns: columns,
  toolbar: toolbarConfig,
  printConfig: printConfig,
  tableExport: tableExport
}
